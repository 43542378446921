<template>
  <v-sheet>
    <v-form v-model="valid">
      <v-text-field required :rules="emailRules" v-model="mail" :placeholder="this.$t('email_label')"></v-text-field>
      <v-select :items="topics" v-model="topic" :placeholder="this.$t('message_subject')"></v-select>
      <v-textarea :placeholder="this.$t('message_label')" :rules="nonEmptyRules" required outlined v-model="message"></v-textarea>
      <v-btn :disabled="!valid" block @click="handleSubmitForm">{{$t('submit')}}</v-btn>
    </v-form>
  </v-sheet>
</template>

<script>
import axios from "axios";
import config from "../config"

export default {

  methods: {
    handleSubmitForm() {
      let postData = {
        template: 'contact',
        title: this.topic,
        topic: this.topic,
        message: {message: this.message, mail: this.mail}
      }
      axios.post(config.MAIL_API_URL, postData)
          .then(response => console.debug('response', response));

    }
  },

  props: {
    topicProp: {
      type: String,
      default: () => ""
    },
  },

  data() {
    return {
      valid: false,
      emailRules: [
        v => !!v || this.$t('email_required'),
        v => /.+@.+/.test(v) || this.$t('email_not_valid'),
      ],
      nonEmptyRules: [
        v => !!v || this.$t('field_required')
      ],
      mail: '',
      topic: this.topicProp,
      topics: [
          "System kolejkowy",
          "Systemy multimedialne",
          "Aplikacje mobilne",
          "Rozwiązania dedykowane",
          "Miejski System Ochrony Uczestników Ruchu - Urbanitus",
          "Ekrany LED",
          "Inny"
      ],
      message: '',
    }
  },

}
</script>
