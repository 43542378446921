<template>
  <div :class="`text-h${level} ${className}`">
    <slot />
  </div>
</template>


<script>
export default {

  props: {
    level: {
      type: Number,
      default: 1
    },
    className: {
      type: String
    }
  }

}
</script>
