<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            dark
            large
            v-bind="attrs"
            v-on="on"
        >

          {{buttonLabel}}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>{{buttonLabel}}</v-card-title>
        <v-card-text>
          <contact-form :topic-prop="topic" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ContactForm from "@/modules/ContactForm";
export default {
  components: {ContactForm},

  props: {
    topic: {
      type: String,
      default: () => "Systemy multimedialne"
    },
    buttonLabel: {
      type: String,
      default: () => "Napisz do nas"
    }
  },
  data () {
    return {
      dialog: false,
    }
  },
}
</script>
