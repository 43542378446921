<template>
  <v-container fluid class="mt-6">
    <v-row
        align="center"
        justify="center"
    >
      <v-col cols="12" sm="12" md="8" xl="8">
        <TextHeading class-name="pb-6" :level=3>{{title}}</TextHeading>
        <div class="grey--text" v-html="subtitle"></div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TextHeading from "@/modules/TextHeading";
export default {
  components: {TextHeading},
  props: {
    title: {
      type: String,
      default: () => ""
    },
    subtitle: {
      type: String,
      default: () => ""
    },
  },
}
</script>
