<template>
  <product-page :title="title" :subtitle="subtitle">
    <contact-popup topic="Rozwiązania dedykowane" />
  </product-page>
</template>

<script>
import ProductPage from "@/components/products/ProductPage";
import ContactPopup from "@/components/ContactPopup";

export default {
  components: {ContactPopup, ProductPage},
  data() {
    //TODO: missing translations or restful request
    return {
      title: "Dedykowane rozwiązania",
      subtitle: "Platforma Biznesu posiada bogate, wieloletnie doświadczenie w zakresie projektowania i rozwoju systemów wbudowanych. Specjalizujemy się w projektowaniu, prototypowaniu, debugowaniu, testowaniu oraz produkcji oprogramowania i sprzętu. Nasza wiedza obejmuje obszary technologiczne, takie jak: systemy multimedialne, aplikacje mobilne, automatyka budynkowa, projektowanie PCB, systemy wbudowane, sterowniki."
    }
  },
}
</script>
